import { toQueryParams } from '@/helpers'
import { z } from 'zod'
import { Address } from './address'
import { paginatedApiCall } from './client'
import { DeliveryItem } from './deliveryItem'
import { type Pagination } from './shared'

export const Delivery = z.object({
  id: z.string(),
  deliveryNumber: z.string(),
  clientCode: z.string(),
  clientName: z.string(),
  createdAt: z.string(),
  shippingDate: z.string(),
  instruction: z.string().optional(),
  comment: z.string().optional(),
  to: Address,
  from: Address,
  deliveryItems: DeliveryItem.array()
})
export type Delivery = z.infer<typeof Delivery>
export const Deliveries = z.array(Delivery)
export type Deliveries = z.infer<typeof Deliveries>

export const DeliveryFilter = z.object({})
export type DeliveryFilter = z.infer<typeof DeliveryFilter>

export const GetDeliveries = (filter: DeliveryFilter, pagination: Pagination) => {
  return paginatedApiCall(
    {
      path: `rest/api/accounts/deliveries?${toQueryParams(filter, pagination).toString()}`,
      method: 'GET'
    },
    Delivery
  )
}
