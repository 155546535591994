<script lang="ts" setup>
import ErrorMessagePanel from '@/components/ErrorMessagePanel.vue'
import { DeliveriesTable } from '@/components/parcelCore'
import { useDeliveriesStore } from '@/stores/parcelCore/deliveries'
import {
  SuiteLoader,
  SuitePageHeader,
  SuitePagination,
  type SuitePaginationEventUpdate
} from '@shipcloud/suite-components'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const deliveriesStore = useDeliveriesStore()
const page = ref(1)
const pageSize = ref(5)
const fetchAll = () => {
  deliveriesStore.fetchAll({}, { pageNumber: page.value, pageSize: pageSize.value })
}
const paginationUpdate = (payload: SuitePaginationEventUpdate) => {
  page.value = payload.page
  pageSize.value = payload.pageSize
  fetchAll()
}
fetchAll()
</script>
<template>
  <SuitePageHeader
    :header="t('Office.ShipmentsDashboard.pageHeader')"
    :subheader="t('Office.ShipmentsDashboard.pageSubheader')"
  />
  <ErrorMessagePanel v-if="deliveriesStore.error">
    {{ t('App.Error.fetchShipmentsError') }}
  </ErrorMessagePanel>
  <SuiteLoader page :loading="deliveriesStore.loading && deliveriesStore.deliveries.length === 0" />
  <SuiteLoader :loading="deliveriesStore.loading && deliveriesStore.deliveries.length > 0">
    <div class="mx-auto max-w-screen-2xl">
      <DeliveriesTable
        class="my-5"
        :deliveries="deliveriesStore.deliveries"
        :loading="deliveriesStore.loading"
      />
      <SuitePagination
        id="pagination"
        :pageSizeOptions="[5, 10, 25, 50]"
        :itemCount="deliveriesStore.total"
        @update="paginationUpdate"
      >
        <template #status="{ itemCount, itemPageEnd, itemPageOffset }">
          {{ t('App.pagination', { itemPageOffset, itemPageEnd, itemCount }) }}
        </template>
      </SuitePagination>
    </div>
  </SuiteLoader>
</template>
