import { z } from 'zod'
import { Shipment } from './shipment'

export const DeliveryItem = z.object({
  id: z.string(),
  length: z.number(),
  width: z.number(),
  height: z.number(),
  weight: z.number(),
  packagingType: z.enum(['CRT', 'PAL', 'ENV', 'TUB', 'BAG']).optional(),
  shipments: Shipment.array()
})
export type DeliveryItem = z.infer<typeof DeliveryItem>
