import {
  ApiError,
  Deliveries,
  Delivery,
  DeliveryFilter,
  GetDeliveries,
  Pagination
} from '@/api/parcelCore'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

interface DeliveriesStore {
  [id: string]: Delivery
}

export const useDeliveriesStore = defineStore('deliveries', () => {
  const deliveriesStore = ref<DeliveriesStore>({})
  const loading = ref(false)
  const error = ref<ApiError>()
  const total = ref<number>(0)
  const filter = ref<DeliveryFilter>()
  const deliveries = computed<Deliveries>(() => Object.values(deliveriesStore.value))

  const fetchAll = async (filter: DeliveryFilter, pagination: Pagination) => {
    loading.value = true
    error.value = undefined
    const result = await GetDeliveries({}, pagination)
    loading.value = false
    if ('status' in result) return (error.value = result)

    deliveriesStore.value = {}
    total.value = result.totalCount
    result.data.forEach((delivery) => {
      deliveriesStore.value[delivery.id] = delivery
    })
  }

  return {
    deliveries,
    error,
    fetchAll,
    loading,
    total,
    filter
  }
})
