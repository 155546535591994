import { z } from 'zod'

export const Pagination = z.object({
  pageNumber: z.number(),
  pageSize: z.number()
})
export type Pagination = z.infer<typeof Pagination>

export const RestParams = z.object({
  method: z.enum(['GET', 'POST', 'PUT', 'DELETE']),
  path: z.string(),
  data: z.any().nullable()
})
export type RestParams = z.infer<typeof RestParams>

export const ApiError = z.object({
  status: z.number()
})
export type ApiError = z.infer<typeof ApiError>

export const createPaginatedResponseSchema = <ItemType extends z.ZodTypeAny>(
  itemSchema: ItemType
) => {
  return z.object({
    data: z.array(itemSchema),
    totalPages: z.number(),
    pageSize: z.number(),
    totalCount: z.number(),
    hasPrevious: z.boolean(),
    hasNext: z.boolean()
  })
}
