import App from '@/App.vue'
import AppV1 from '@/views/AppV1.vue'
import AppV2 from '@/views/AppV2.vue'
import AuthenticationError from '@/views/AuthenticationError.vue'
import SuiteEntry from '@/views/SuiteEntry.vue'
import SuiteEntryV2 from '@/views/SuiteEntryV2.vue'
import ShipmentDetails from '@/views/office/ShipmentDetails.vue'
import ShipmentForm from '@/views/office/ShipmentForm.vue'
import ShipmentsDashboard from '@/views/office/ShipmentsDashboard.vue'
import ShippingRuleTree from '@/views/office/ShippingRuleTree.vue'
import DeliveriesDashboard from '@/views/parcelCore/DeliveriesDashboard.vue'
import DeliveryDetails from '@/views/parcelCore/DeliveryDetails.vue'

export const routes = [
  {
    path: '',
    component: App,
    children: [
      {
        path: '/v1',
        name: 'app-v1',
        component: AppV1,
        children: [
          {
            path: '',
            name: 'suite-entry',
            component: SuiteEntry
          },
          {
            path: 'office',
            children: [
              {
                path: 'shipments-dashboard',
                name: 'shipments-dashboard',
                component: ShipmentsDashboard
              },
              {
                path: 'shipments/:id',
                name: 'shipment-details',
                component: ShipmentDetails,
                props: true
              },
              {
                path: 'shipments/:id/new',
                name: 'create-shipment-from-existing',
                component: ShipmentForm,
                props: (route: any) => ({ id: route.params.id, createReturn: false })
              },
              {
                path: 'shipments/:id/new-return',
                name: 'create-return-from-existing',
                component: ShipmentForm,
                props: (route: any) => ({ id: route.params.id, createReturn: true })
              },
              {
                path: 'shipments/new',
                name: 'create-shipment',
                component: ShipmentForm,
                props: { createReturn: false }
              },
              {
                path: 'shipments/new-return',
                name: 'create-return',
                component: ShipmentForm,
                props: { createReturn: true }
              },
              {
                path: 'routing-rules',
                name: 'routing-rules',
                component: ShippingRuleTree
              }
            ]
          }
        ]
      },
      {
        path: '/v2',
        name: 'app-v2',
        component: AppV2,
        children: [
          {
            path: '',
            name: 'suite-entry-v2',
            component: SuiteEntryV2
          },
          {
            path: 'office',
            children: [
              {
                path: 'shipments-dashboard',
                name: 'parcel-core-shipments-dashboard',
                component: DeliveriesDashboard
              },
              {
                path: 'shipments/:id',
                name: 'parcel-core-shipment-details',
                component: DeliveryDetails,
                props: true
              }
            ]
          }
        ]
      },
      {
        path: '/',
        name: 'authentication-error',
        component: AuthenticationError
      }
    ]
  }
]
