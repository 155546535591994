import type { Address, Delivery, DeliveryItem, Event, Shipment } from '@/api/parcelCore'
import {
  StatusCaption,
  StatusColorEnum,
  SuiteFlag,
  type SuiteItemListItem
} from '@shipcloud/suite-components'
import { markRaw } from 'vue'
import { useI18n } from 'vue-i18n'
import type { DeliveryTableRow, SingleDeliveryTableRow } from './models'

export const useDeliveries = () => {
  const { d, t } = useI18n()
  const milestoneCaption = (event?: Event) => {
    switch (event?.milestone) {
      case 'Cancel':
        return { milestone: t('Milestone.cancel'), milestoneColor: StatusColorEnum.RED }
      case 'Delivered':
        return { milestone: t('Milestone.delivered'), milestoneColor: StatusColorEnum.GREEN }
      case 'Exception':
        return { milestone: t('Milestone.exception'), milestoneColor: StatusColorEnum.RED }
      case 'HUBIn':
        return { milestone: t('Milestone.hubIn'), milestoneColor: StatusColorEnum.BLACK }
      case 'InTransit':
        return { milestone: t('Milestone.inTransit'), milestoneColor: StatusColorEnum.BLACK }
      case 'OutForDelivery':
        return { milestone: t('Milestone.outForDelivery'), milestoneColor: StatusColorEnum.BLACK }
      case 'Ready':
        return { milestone: t('Milestone.ready'), milestoneColor: StatusColorEnum.BLACK }
      case 'Return':
        return { milestone: t('Milestone.return'), milestoneColor: StatusColorEnum.RED }
      default:
        return { milestone: t('Milestone.none'), milestoneColor: StatusColorEnum.BLACK }
    }
  }
  const formatDate = (date: string): string => {
    try {
      return d(date)
    } catch {
      return ''
    }
  }
  const formatRecipient = (address: Address): string => {
    return [address.addressLine1, address.postalCode, address.city].join(' ')
  }
  const formatTrackingNumber = (trackingNumber: string | undefined): string =>
    trackingNumber ? trackingNumber : ''

  const filterShipmentByType = (deliveryItem: DeliveryItem): Shipment =>
    deliveryItem.shipments.filter((shipment) =>
      ['LastMile', 'DirectShipping'].includes(String(shipment.transportStepKind))
    )[0]

  const formatDeliveryItem = (
    deliveryItem: DeliveryItem,
    delivery: Delivery
  ): SingleDeliveryTableRow => {
    const shipment = filterShipmentByType(deliveryItem)
    const shipmentItem = shipment.shipmentItem
    const latestEvent = shipmentItem.events?.pop()
    const shipmentItemRow: SingleDeliveryTableRow = {
      ...milestoneCaption(latestEvent),
      deliveryId: delivery.id,
      trackingId: formatTrackingNumber(shipmentItem.trackingNumber),
      carrier: shipment.carrierName,
      service: shipment.productName,
      creationDate: formatDate(delivery.createdAt),
      recipient: formatRecipient(delivery.to),
      country: delivery.to.countryCode
    }
    return shipmentItemRow
  }

  const formatDelivery = (apiDelivery: Delivery): DeliveryTableRow => {
    const itemRows = apiDelivery.deliveryItems.map((deliveryItem) =>
      formatDeliveryItem(deliveryItem, apiDelivery)
    )
    const firstItemRow = itemRows[0]
    // is there more than one deliveryItem = parcel (?)
    if (itemRows.length > 1)
      return {
        ...firstItemRow,
        trackingId: t('ParcelCore.multipleDelivery'),
        milestone: '',
        children: itemRows
      }
    else return { ...firstItemRow, children: [] }
  }

  const toSingleItem = (row: DeliveryTableRow): SuiteItemListItem => {
    return {
      cells: [
        row.trackingId,
        {
          content: row.milestone,
          props: { color: row.milestoneColor },
          component: markRaw(StatusCaption)
        },
        row.carrier,
        row.service,
        row.creationDate,
        row.recipient,
        {
          component: markRaw(SuiteFlag),
          props: { country: row.country }
        }
      ]
    }
  }

  const deliveryToTableRow = (apiDelivery: Delivery): SuiteItemListItem => {
    const row = formatDelivery(apiDelivery)
    const item = toSingleItem(row)
    if (row.children.length) {
      // we only support one children level
      item.children = row.children.map((childRow) => {
        return toSingleItem({ ...childRow, children: [] })
      })
    }
    return item
  }

  return { deliveryToTableRow }
}
