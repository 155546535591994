<script lang="ts" setup>
import { type Deliveries } from '@/api/parcelCore'
import router from '@/router'
import {
  SuiteItemList,
  type SuiteIconName,
  type SuiteItemListItem
} from '@shipcloud/suite-components'
import { ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useDeliveries } from './useDeliveries'

type DeliveriesTableProps = { deliveries: Deliveries }
const props = defineProps<DeliveriesTableProps>()
const { t } = useI18n()
const { deliveryToTableRow } = useDeliveries()
const items = ref<SuiteItemListItem[]>([])
const headings = [
  t('Package.carrier_tracking_no'),
  t('Package.status'),
  t('Shipment.carrier'),
  t('Shipment.service'),
  t('Shipment.created_at'),
  t('Office.ShipmentsDashboard.recipient'),
  t('Office.ShipmentsDashboard.shipToCountry')
]
const itemActions = [
  {
    action: 'details',
    icon: 'ZoomIn' as SuiteIconName
  }
]
const fillRows = () => {
  items.value = props.deliveries.map(deliveryToTableRow).filter((row) => row !== undefined)
}
watch(props, fillRows, { immediate: true })
const onAction = (payload: { action: string; data: any }) => {
  router.push({ name: 'parcel-core-shipment-details', params: { id: payload.data.cells[0] } })
}
</script>

<template>
  <SuiteItemList :headings :items :itemActions @action="onAction" />
</template>
