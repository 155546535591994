import { z } from 'zod'

export const Address = z.object({
  addressLine1: z.string().optional(),
  addressLine2: z.string().optional(),
  addressLine3: z.string().optional(),
  street: z.string(),
  houseNumber: z.string().optional(),
  postalCode: z.string().optional(),
  city: z.string().optional(),
  countryCode: z.string(),
  contact: z.string().optional(),
  phone: z.string().optional(),
  eMail: z.string().optional()
})
export type Address = z.infer<typeof Address>
