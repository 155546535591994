import { z } from 'zod'
import { ShipmentItem } from './shipmentItem'

export const Shipment = z.object({
  id: z.string(),
  shipmentNumber: z.string(),
  trackingNumber: z.string().optional(),
  productCode: z.string(),
  productName: z.string(),
  carrierCode: z.string(),
  carrierName: z.string(),
  transportStepKind: z
    .enum([
      'CrossdockToCrossdock',
      'DirectShipping',
      'LastMile',
      'LinehaulToDestinationCountry',
      'PickUpAndShipToCrossdock'
    ])
    .optional(),
  shipmentItem: ShipmentItem
})
export type Shipment = z.infer<typeof Shipment>
