import { z } from 'zod'

export const MILESTONES = [
  'Cancel',
  'Delivered',
  'Exception',
  'HUBIn',
  'InTransit',
  'None',
  'OutForDelivery',
  'Ready',
  'Return'
] as const

export const Event = z.object({
  timestamp: z.string(),
  location: z.string().optional(),
  status: z.string().optional(),
  milestone: z.enum(MILESTONES).optional(),
  comment: z.string().optional()
})
export type Event = z.infer<typeof Event>
